import { StickerLabelVariant } from '@stitch-fix/mode-react';
import { ModeStickerLabelTheme } from '../../types/definitions/vendor/graphql-schema.d';

export const themeMapping: Record<ModeStickerLabelTheme, StickerLabelVariant> =
  {
    CITRUS: 'citrus',
    NAVY: 'navy',
    WHITE: 'white',
    MINT: 'mint',
    DEFAULT_INVERSE: 'default',
    DEFAULT_LIGHT: 'default',
  };
